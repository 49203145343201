<template>    
    <div>
        <div class="text-align">
            <h1 class="font-25 l-h-40 m-t-20" style="letter-spacing: 1px;">百春幼儿园预报名登记</h1>
            <p class="l-h-30" style="color: #666666;">请认真填写基础信息</p>
        </div>
        
        <div class="m-a" style="width: 65%;padding: 15px 0 20px;">
            <van-steps :active="0">
                <van-step>填写基础信息</van-step>
                <van-step>完善个人信息</van-step>
            </van-steps>
        </div>
        <van-form validate-first @failed="onFailed" @submit="formSubmit">
            <van-field
                v-model="form.name"
                label="姓名"
                name="name"
                required
                placeholder="请输入幼儿姓名"
                :rules="[{ validator: nameVerification, message: '幼儿姓名必填项'}]"
            />
            
            <van-field name="sex" label="性别" required>
                <template #input>
                    <van-radio-group v-model="form.sex" direction="horizontal" @change="sexSelected">
                        <van-radio name="1">男</van-radio>
                        <van-radio name="2">女</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
            
            <van-field
                v-model="form.idCard"
                label="身份证号"
                required
                name="idCard"
                placeholder="身份证号"
                :rules="[{ validator:cardIdVerification,message: '请输入正确的身份证号' }]"
            />
            
            <divider-10></divider-10>
            <van-divider>监护人</van-divider>
            <div v-for="(item,index) in form.jiashu" :key="index">
                <jiashu-guanxi :item="item" :index="index" :guardianshipFee="guardianshipFee" @guanxiSelected="guanxiSelected"></jiashu-guanxi>
            </div>
            
            <div style="margin: 16px;">
                <van-button round block type="primary" native-type="submit">下一步</van-button>
            </div>
        </van-form>
    </div>
</template>

<script>
    import jiashuGuanxi from '../../components/form/jiashu-guanxi.vue'
    
    export default {
        components:{
            jiashuGuanxi
        },
        data() {
            return {
                form:{
                    name:'',
                    phone:'',
                    sex:'',
                    idCard:'',
                    jiashu:[    // 家属
                        {
                            name:'',
                            guanxi:'',
                            tel:''
                        }
                    ]
                },
                guardianshipFee: ['父亲','母亲','爷爷','奶奶'],
                nameStatus:false,
                idCardStatus:false,
                leaveStatus:false,
                schoolInfo:{}
            }
        },
        mounted() {
            this.getTime()
        },
        methods:{
            onClickLeft() {
                this.$router.go(-1);
            },
            guanxiSelected(e){
                this.form.jiashu[e.index].guanxi = e.value
            },
            sexSelected(){
                this.search()
            },
            nameVerification(value){ // 姓名验证
                let nameStatus = /^[\u4E00-\u9FA5]{2,20}$/.test(value)
                this.nameStatus = nameStatus
                this.search()
                return nameStatus
            },
            cardIdVerification(value){ // 身份证号验证
                let idCardStatus = /^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$/.test(value);
                this.idCardStatus = idCardStatus
                this.search()
                return idCardStatus
            },
            search(){  // 验证身份证是否已存在
                if(!this.nameStatus || this.form.sex=='' || !this.idCardStatus){
                    console.log('请填写信息')
                    return false
                }
                this.$api.register.studentIdCard({name:this.form.name, idCard:this.form.idCard}).then((res) => {
                    console.log(res)
                    if(res.data.status === false){
                        console.log('信息已存在')
                        this.dialog()
                    }
                })
            },
            formSubmit(){
                // 再次验证是否要可执行时间内
                this.timeVerification(this.schoolInfo)
                
                let form = this.form
                // 把信息存储在本地
                this.$storage.setItem('studentForm',form)
                this.$router.push({
                    path:'/register/form'
                })
            },
            onFailed(errorInfo) {
                // console.log('failed', errorInfo);
                this.$toast.fail(errorInfo.errors[0].message)
            },
            dialog(){
                if(this.leaveStatus) return false               
                this.$dialog.alert({
                    message: '学生信息已存在！',
                    confirmButtonText:'查看信息',
                    theme: 'round-button',
                })
                .then(() => {
                    // 确认
                    // console.log('跳转')
                    this.leaveStatus = true
                    this.$router.push({
                        path:'/register/query'
                    })
                });
            },
            getTime(){
                    this.$api.common.getSchoolInfo().then((res) => {
                    let data = res.data
                    this.schoolInfo = data
                    this.timeVerification(data)
                })
            },
            timeVerification(data){
                let timeStatus = this.isDuringDate(data.open_at, data.end_at)

                if(!timeStatus){
                    this.$dialog.alert({
                        message: '预报名暂未开始，敬请持续关注学校公告！',
                        confirmButtonText: '返回'
                    }).then(() => {
                        this.onClickLeft()
                    });
                }
            },
            isDuringDate(beginDateStr, endDateStr) {
                var curDate = new Date(),
                beginDate = new Date(beginDateStr),
                endDate = new Date(endDateStr);
                if (curDate >= beginDate && curDate <= endDate) {
                    return true;
                }
                return false;
            }
        }
    }
</script>

<style>
</style>
